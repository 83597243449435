<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: dotContainer.vue
Description: This file contains the card component with background as dots that is used in signin, signup page
-->
<template>
  <el-row type="flex" class="dot-container" :justify="justifyContent">
    <img src="@/assets/dot-a.svg" />
    <div :class="`caption-text ${justifyContent}`">
      <div class="header">
        <slot name="header"></slot>
      </div>
      <div class="main">
        <slot name="main"></slot>
      </div>
      <div class="footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </el-row>
</template>

<script>
export default {
  props: {
    justifyContent: {
      default: "start"
    }
  }
};
</script>

<style lang="scss">
$white: #ffffffa3;
.dot-container {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  img {
    width: 100%;
    position: absolute;
  }
  .caption-text {
    color: $white;
    float: left;
    position: absolute;
    @include responsiveProperty(top, 35%, 35%, 31%);
    width: 40%;
    left: 10vw;
    .header {
      @include responsiveProperty(font-size, 1.7em, 1.5em, 1.4em);
    }
    .main {
      margin-top: 5%;
      margin-bottom: 10%;
    }
    .footer {
      .el-button,
      .el-button--text:focus {
        color: $white;
        border: 1px solid $white;
        padding: 5px 20px;
        border-radius: 2%;
        font-size: 2.3vh;
      }
      .el-button--text:hover {
        background-color: #279427b4;
      }
    }
  }
}
</style>
