<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: signUp.vue
Description: This file contains vue component which contains the form used of user registration process
-->
<template>
  <el-row type="flex" class="signup-card" ref="signUpCard">
    <el-col :span="10">
      <card>
        <template slot="title">
          <h2>{{ $t("Usrs_sign_up") }}</h2>
        </template>
        <template slot="subtitle">
          <p class="header">{{ $t("welcome_back_sign_up") }}</p>
          <!-- <br />/ -->
          <!-- &nbsp; -->
          <p class="title">{{ $t("create_account_manange_pond_anywhere") }}</p>
        </template>
        <template slot="form">
          <el-alert
            v-if="ehm__unhandledErrorMessage"
            :title="$t(ehm__unhandledErrorMessage)"
            type="error"
            @close="ehm__handleAlertClose"
          ></el-alert>
          <er-form
            :ref="formName"
            :model="user"
            size="mini"
            v-loading="loading"
            element-loading-background="white"
            :element-loading-text="$t('Comn_auth_loading')"
          >
            <el-row>
              <el-col :span="12">
                <er-form-item
                  prop="first_name"
                  class="name-element"
                  :error="
                    aekmm__castErrorKeyToLang(ehm__errMessagesObject.first_name)
                  "
                >
                  <er-input
                    v-model="user.first_name"
                    :placeholder="userFormLabels[0].placeholder"
                  >
                    <template slot="prepend">
                      <img class="img-icon" src="@/assets/user.svg" />
                    </template>
                  </er-input>
                </er-form-item>
              </el-col>
              <el-col :span="12">
                <er-form-item
                  prop="last_name"
                  class="name-element"
                  :error="
                    aekmm__castErrorKeyToLang(ehm__errMessagesObject.last_name)
                  "
                >
                  <er-input
                    v-model="user.last_name"
                    :placeholder="userFormLabels[1].placeholder"
                  >
                    <template slot="prepend">
                      <img class="img-icon" src="@/assets/user.svg" />
                    </template>
                  </er-input>
                </er-form-item>
              </el-col>
            </el-row>

            <er-form-item
              prop="email"
              :error="aekmm__castErrorKeyToLang(ehm__errMessagesObject.email)"
            >
              <er-input
                v-model="user.email"
                :placeholder="userFormLabels[2].placeholder"
              >
                <template slot="prepend">
                  <span class="material-icons-round icon--prefix"> email </span>
                </template>
              </er-input>
            </er-form-item>
            <er-form-item
              prop="country"
              :error="
                aekmm__castErrorKeyToLang(ehm__errMessagesObject.country.name)
              "
            >
              <er-select
                v-model="user.country"
                value-key="code"
                autocomplete="new-country"
                filterable
                :placeholder="userFormLabels[3].placeholder"
              >
                <template slot="prefix">
                  <img class="img-icon" src="@/assets/location.svg" />
                </template>
                <template slot="default">
                  <er-option
                    v-for="(name, code) in countries"
                    :key="code"
                    :label="name"
                    :value="{ name, code }"
                  ></er-option>
                </template>
              </er-select>
            </er-form-item>
            <er-form-item
              prop="phone"
              :error="aekmm__castErrorKeyToLang(ehm__errMessagesObject.phone)"
            >
              <er-input
                class="country-select input-box"
                v-model="user.phone"
                :placeholder="userFormLabels[4].placeholder"
                autocomplete="off"
              >
                <template slot="prepend">
                  <img class="img-icon" src="@/assets/mobile.svg" />
                </template>
              </er-input>
            </er-form-item>
            <el-row>
              <el-col :span="12">
                <er-form-item
                  prop="password"
                  :error="
                    aekmm__castErrorKeyToLang(ehm__errMessagesObject.password)
                  "
                >
                  <er-input
                    v-model="user.password"
                    :placeholder="userFormLabels[5].placeholder"
                    show-password
                    autocomplete="new-password"
                    class="er-input--password"
                    ref="passwordField"
                    @input="pvm__getPercentage"
                    :style="
                      `--width:${pvm__passwordPercentage.width}px;--color:${pvm__passwordPercentage.color};--left:${pvm__passwordPercentage.left}px`
                    "
                  >
                    <template slot="prepend">
                      <span class="material-icons-round icon--prefix">
                        lock
                      </span>
                    </template>
                  </er-input>
                </er-form-item>
              </el-col>
              <el-col :span="12">
                <er-form-item
                  prop="confirm_password"
                  :error="
                    aekmm__castErrorKeyToLang(
                      ehm__errMessagesObject.confirm_password
                    )
                  "
                >
                  <er-input
                    v-model="user.confirm_password"
                    :placeholder="userFormLabels[6].placeholder"
                    @keyup.native.enter="submitForm"
                    show-password
                  >
                    <template slot="prepend">
                      <span class="material-icons-round icon--prefix">
                        lock
                      </span>
                    </template>
                  </er-input>
                </er-form-item>
              </el-col>
            </el-row>
            <el-row class="password-validations-container bottom-space">
              <span class="header">
                {{ $t("Usrs_pwd_requirement_header") }}
              </span>
              <ul class="validation-rules">
                <li
                  v-for="(validation, id) in pvm__passwordValidations"
                  :key="id"
                  :class="[
                    'validation-rules-row',
                    validation.validator() && 'is-checked'
                  ]"
                >
                  <div class="title">
                    <p>{{ ftm__capitalize(validation.content) }}</p>
                  </div>
                </li>
              </ul>
            </el-row>
            <!-- </div> -->
            <el-row type="flex" justify="space-around" class="btn-container">
              <er-button class="btn_submit" size="mini" @click="submitForm">
                {{ $t("Usrs_sign_up") }}
                <span class="material-icons-round icon__suffix--space">
                  login
                </span>
              </er-button>
            </el-row>
          </er-form>
        </template>
      </card>
    </el-col>
    <el-col :span="14">
      <dot-container justify-content="end">
        <template slot="header">
          <p>{{ $t("already_have_an_account") }}</p>
        </template>
        <template slot="main">
          <p>{{ $t("eruvaka_company_description_culture") }}</p>
        </template>
        <template slot="footer">
          <router-link to="/sign-in">
            <er-button type="text">{{ $t("Harvest_log_in") }}</er-button>
          </router-link>
        </template>
      </dot-container>
    </el-col>
    <div class="company_info">
      <p style="display: flex; margin: 0px 25px 10px 0px">
        <span style="color: #ffffff; font-weight: 600">{{
          $t("eruvaka-technologies", { year: new Date().getFullYear() })
        }}</span>
        <span style="color: #ffffff; margin: 0px 5px">|</span>
        <a
          style="color: #b1c1c0; cursor: pointer; text-decoration: none"
          href="https://eruvaka.com/privacy"
          target="_blank"
          >{{ $t("privacy-policy") }}
        </a>
        <span style="color: #ffffff; margin: 0px 5px">|</span>
        <a
          style="color: #b1c1c0; cursor: pointer; text-decoration: none"
          href="https://eruvaka.com/terms"
          target="_blank"
          >{{ $t("terms-and-conditions") }}
        </a>
      </p>
    </div>
  </el-row>
</template>
<script>
import User from "@/model/user.js";
import { createNamespacedHelpers } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin.js";
import redirectsMixin from "@/mixins/redirectsMixin.js";
import passwordValidationMixin from "@/mixins/passwordValidationMixin.vue";
import authErrorKeyMapMixin from "@/mixins/authErrorKeyMapMixin.js";
import filtersMixin from "@/mixins/filtersMixin.js";
import dotContainer from "./dotContainer";
import card from "./card";
import {
  erInput,
  erButton,
  erSelect,
  erOption,
  erForm,
  erFormItem
} from "@/components/base/basePondlogs";
const { mapActions } = createNamespacedHelpers("auth");
export default {
  mixins: [
    errorHandlerMixin,
    redirectsMixin,
    authErrorKeyMapMixin,
    passwordValidationMixin,
    filtersMixin
  ],
  components: {
    erInput,
    erButton,
    erForm,
    erFormItem,
    erOption,
    erSelect,
    card,
    dotContainer
  },
  data: function() {
    return {
      user: new User(),
      countries: {},
      formName: "signUpForm",
      countryCode: "",
      loading: false,
      ehm__errMessagesObject: new User(),
      hasError: false
    };
  },
  computed: {
    getTimeZones() {
      return this.$store.getters["user/getTimeZones"];
    },
    userFormLabels() {
      return [
        {
          label: this.$t("Usrs_first_name"),
          placeholder: this.$t("Usrs_enter_first_name")
        },
        {
          label: this.$t("Usrs_last_name"),
          placeholder: this.$t("Usrs_enter_last_name")
        },
        {
          label: this.$t("Comn_email_address"),
          placeholder: this.$t("Usrs_enter_email_addr")
        },
        {
          label: this.$t("Comn_country"),
          placeholder: this.$t("Usrs_enter_country")
        },
        {
          label: this.$t("Comn_mobile_num"),
          placeholder: this.$t("Usrs_enter_mobile_num")
        },
        {
          label: this.$t("Usrs_pwd"),
          placeholder: this.$t("Usrs_enter_pwd")
        },
        {
          label: this.$t("Comn_confirm_pwd"),
          placeholder: this.$t("Usrs_enter_confirm_pwd")
        }
      ];
    },
    aekmm__castErrorKeyToLang() {
      return ({ key, error_code }) => {
        return this.aekmm__authErrorLangKeyMapper(key, error_code);
      };
    }
  },
  async mounted() {
    try {
      await this.fetchCountryList();
      this.countries = this.$store.getters["auth/getCountries"];
      this.$nextTick(() => {
        this.setRefsForPasswordValidations(
          this.$refs.signUpCard.$el,
          this.$refs.passwordField
        );
      });
    } catch (err) {
      this.ehm__errorMessages(err, false);
    }
  },
  methods: {
    ...mapActions({
      signUp: "signUp",
      fetchCountryList: "fetchCountryList"
    }),
    resetForm() {
      this.user = new User();
      this.countryCode = "+91";
    },
    async handleCountryChange(selectedCountry) {
      try {
        await this.$store.dispatch("user/fetchTimeZones", selectedCountry.code);
        this.timezones = this.getTimeZones;
        this.user.timezone = this.timezones[Object.keys(this.getTimeZones)[0]];
      } catch (err) {
        console.error(err);
      }
    },
    ehm__error403Handler: function(error) {
      console.error(error);
      this.ehm__unhandledErrorMessage = "Usrs_email_already_in_use";
    },
    async submitForm() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      try {
        this.user.phone = this.countryCode + this.user.phone;
        if (this.user.country.name !== "") {
          await this.handleCountryChange(this.user.country);
        }
        this.ehm__errMessagesObject = new User();
        await this.signUp(this.user);
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: this.$t(
            "signup.congratulations-your-account-has-been-successfully-created"
          ),
          duration: 10000,
          type: "success"
        });
        this.$router.push({
          path: "/sign-up-status",
          params: {
            message_status: "success",
            page: "sign_up",
            message_key: "Usrs_confirm_email"
          }
        });
      } catch (err) {
        this.ehm__errorMessages(err, false);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss">
.signup-card {
  grid-column-start: 5 !important;
  grid-column-end: 8 !important;
  .el-card__header {
    padding: 1.2em 3em;
  }
  .el-card__body {
    padding: 0em 2em 0em 2em;
  }
  .card-subtitle {
    width: 14em;
    margin-top: 15px;
    .header {
      margin-bottom: 2px;
      @include responsiveProperty(font-size, 1.1em, 1.1em, 1.2em);
    }
    .title {
      @include responsiveProperty(font-size, 0.9em, 0.8em, 0.8em);
    }
  }
  .el-select {
    width: 100%;
  }
  .user-properties-element {
    // width: 80%;
    text-align: center;
    display: block;
  }
  .btn-container {
    line-height: 2;
    padding: 0.2em;
  }
  .company_info {
    position: fixed;
    @include responsiveProperty(bottom, 2px, 4px, 6px);
    @include responsiveProperty(right, 2px, 4px, 6px);
  }
}
.country-code-popper {
  width: 10% !important;
  margin-top: 5px;
  li {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    line-height: 1;
    .country {
      width: 30%;
      margin: 0px 10px;
      right: 0%;
      text-align: left;
    }
    .country-code {
      width: 70%;
      margin: 0px 10px;
      left: 0%;
    }
  }
  .popper__arrow {
    left: 12% !important;
  }
}
</style>
